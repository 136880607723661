// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Montserrat-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/Proza-Display-W03-Bold.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n\n  @font-face {\n    font-family: 'Montserrat';\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n  }\n  \n  @font-face {\n    font-family: 'Proza Display';\n    font-weight: 700;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n  }\n\n", "",{"version":3,"sources":["webpack://src/css/tailwind.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;;EAGjB;IACE,yBAAyB;IACzB,gBAAgB;IAChB,4DAA2D;EAC7D;;EAEA;IACE,4BAA4B;IAC5B,gBAAgB;IAChB,4DAA+D;EACjE","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n\n  @font-face {\n    font-family: 'Montserrat';\n    font-weight: 400;\n    src: url(../fonts/Montserrat-Regular.woff2) format('woff2');\n  }\n  \n  @font-face {\n    font-family: 'Proza Display';\n    font-weight: 700;\n    src: url(../fonts/Proza-Display-W03-Bold.woff2) format('woff2');\n  }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
