import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogItem from "../components/BlogItem";
import * as Constants from '../other/Constants.js'
import { CompanyLogos } from "../other/CompanyLogos";

import '../css/tailwind.css';

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.onClickScheduleMeeting = this.onClickScheduleMeeting.bind(this);
    this.onClickLinkedIn = this.onClickLinkedIn.bind(this);
  }

  /* Event handlers */
  onClickScheduleMeeting(event) {
    //console.log('The calendly button was clicked.');
    window.open(Constants.BOOKING_URL, '_blank');
    return;
  }

  onClickLinkedIn(event) {
    window.open(Constants.LINKEDIN_URL, '_blank');
    return;
  }

  onClickTwitter(event) {
    window.open(Constants.TWITTER_URL, '_blank');
    return;
  }

  /* Style */
  sectionHeaderStyle_BaseNew() {
    return "text-4xl text-left font-brandheader font-bold text-brandnavyblue"
  }

  mainContentBodyStyle_BaseNew() {
    return "text-lg md:text-lg text-left text-branddarkgrey font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug"
  }

  mainContentBodyStyle_BaseNewEmphasis() {
    return "text-lg md:text-lg text-left text-brandskyblue font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug"
  }

  buildEmphasisLine(text, spacing, textstyle) {
    
    return (
      <div className={"container flex flex-row w-full items-start " + spacing }  >
        <img className={"w-4 mr-2 mt-0.5"} src="icons/blue_arrow.png" alt="Blue highlight arrow" />
        <h1 className={this.mainContentBodyStyle_BaseNew() + textstyle}>
          {text}
        </h1>
      </div>
    )
  }

  /* UI sections */
  renderTopSection() {

    return (

      <section className="bg-brandskyblue pt-24 pb-24 md:pt-48 md:pb-48">

        <div className="w-full container flex flex-col ml-2 md:mx-auto lg:px-16">

          <h1 className="w-8/12 md:w-5/12 text-7xl text-left font-brandheader font-bold text-brandnavyblue">
            Hello, I&rsquo;m Mike.
          </h1>

          <h1 className="w-10/12 md:w-6/12 text-4xl md:text-4xl text-left text-brandwhite font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug mt-6 lg:mt-6 mb-6 sm:mb-2">
            I'm a highly technical startup CTO with a product focus, helping ambitious tech startups solve big problems and grow.
          </h1>
        </div>

      </section>
    );
  }

  renderWhatIDo() {

    // Safe link to  own website 
    // eslint-disable-next-line react/jsx-no-target-blank
    let pointone = ["Entrepreneur and founder at ", <a href="https://www.pfnexus.com" className="text-brandskyblue" target="_blank" rel="noopener">PF Nexus</a>,  " accelerating the global transition to renewable forms of energy."]

    return (

      <section className="bg-brandwhite pt-24 pb-24 md:pt-48 md:pb-48">

        <div className="w-full container flex flex-col ml-2 md:mx-auto lg:px-16">

          <h1 className={this.sectionHeaderStyle_BaseNew()}>
            What I do
          </h1>

          {this.buildEmphasisLine(pointone, " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-11/12 md:w-5/12")}
          {this.buildEmphasisLine("Consultant CTO: helping fast-growing tech startups solve big problems and grow. Focus on Deep tech, Machine Learning & IoT.", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-11/12 md:w-5/12")}
          {this.buildEmphasisLine("Startup Advisor and Mentor: working with early stage tech companies that are tackling real problems.", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-11/12 md:w-5/12")}

        </div>

      </section>
    );
  }

  renderAboutMe() {

    return (

      <section className="bg-brandcream pt-24 pb-24 md:pt-48 md:pb-48">

        <div className="w-full container flex flex-col ml-2 md:mx-auto lg:px-16">

          <h1 className={this.sectionHeaderStyle_BaseNew()}>
            About me
          </h1>

          <h1 className={this.mainContentBodyStyle_BaseNew() + " w-11/12 md:w-5/12 mt-6 lg:mt-6 mb-6 sm:mb-2"}>
            I am an Entrepreneur, software engineer, business builder and passionate supporter of the Startup ecosystem in London and around the world.
          </h1>
          <h1 className={this.mainContentBodyStyle_BaseNew() + " w-11/12 md:w-5/12 mt-6 lg:mt-6 mb-6 sm:mb-2"}>
            I build companies that use innovative technology to make the world a better place, whether that's by disrupting existing industries or by creating entirely new ones. Supporter of the Tech4Good movement.
          </h1>
          <h1 className={this.mainContentBodyStyle_BaseNew() + " w-11/12 md:w-5/12 mt-6 lg:mt-6 mb-6 sm:mb-2"}>
            I’m driven to build high performing teams and delight in making software teams faster and more effective.
          </h1>
        </div>

      </section>
    );
  }

  renderConsultantCTO() {

    return (
      <section className="w-full h-auto container bg-brandwhite ml-2 md:mx-auto lg:px-16 mt-32 mb-16" >

        <h1 className={this.sectionHeaderStyle_BaseNew()}>
          Consultant CTO
        </h1>

        <div className="h-auto container flex flex-wrap md:flex-nowrap mt-16" >

          <div className="w-full md:w-1/2 md:mr-4 ">

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mb-6 sm:mb-2"}>
              I work as Consultant CTO (Interim or Fractional) for ambitious tech startups. As a technologist, my focus is Mobile, Web, IoT & AI (Machine Learning & Deep Learning).
              </h1>

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mt-6 lg:mt-6 mb-6 sm:mb-2"}>
              My specialities include; team management, tech hiring, building lean MVPs, product roadmap planning, scaling, agile project management, budgeting, supporting investment campaigns and process improvement.
              </h1>

          </div>


          <div className="w-full md:w-1/2 md:ml-4 ">

            <h1 className={this.mainContentBodyStyle_BaseNewEmphasis()}>
              With many clients, I also help them solve general commercial problems:
            </h1>

            {this.buildEmphasisLine("Improving understanding of their customers", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-8/12")}
            {this.buildEmphasisLine("Reaching product-market fit", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-8/12")}
            {this.buildEmphasisLine("Improving non-technical processes to increase revenue (e.g. sales and onboarding processes)", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-8/12")}
            {this.buildEmphasisLine("Coping with exponential growth", " mt-6 lg:mt-6 mb-6 sm:mb-2 ", " w-8/12")}

          </div>

        </div>
      </section>
    );
  }

  renderDivider() {

    return (
      <section className="w-full mx-auto" >
        <div className="border-t-2 border-brandgrey mt-0 ml-16 mr-16 md:ml-48 md:mr-48"></div>
      </section>
    );
  }

  renderStartupAdvisor() {

    return (
      <section className="w-full h-auto container bg-brandwhite ml-2 md:mx-auto lg:px-16 mt-32 mb-16" >

        <div className="h-auto container flex flex-wrap md:flex-nowrap mt-16" >

          <div className="w-full md:w-1/2 md:mr-4">

            <h1 className={this.sectionHeaderStyle_BaseNew() + " mb-12"}>
              Startup Advisor and Mentor
            </h1>

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mb-6 sm:mb-2"}>
              I enjoy working with early stage tech companies that are tackling large problems and have clarity on who/what/why.
            </h1>

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mt-6 lg:mt-6 mb-6 sm:mb-2"}>
              Particularly interested in SaaS, platforms, AI, IoT and API-based businesses in either healthcare, privacy, clean tech or social & sustainable impact.
            </h1>

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mt-6 lg:mt-6 mb-6 sm:mb-2"}>
              I also perform technical due diligence for investors as part of their funding or acquisition process.
            </h1>

          </div>


          <div className="w-full md:w-1/2 md:ml-4">
            {this.renderLogosDesktop()}
          </div>

        </div>
      </section>
    );
  }

  renderLogos(width) {
    return CompanyLogos.map((c) => (
      <div key={c.alt} className={width}>
        <img src={`${c.path}`} alt={c.alt} />
      </div>
    ));
  }

  renderLogosDesktop() {
    const width = "w-36 mr-16";
    return (
      <div className="mx-2">

        <div className="flex flex-row items-center">
          {this.renderLogos(width).slice(0, 2)}
        </div>

        <div className="flex flex-row items-center mt-20">
          {this.renderLogos(width).slice(2, 4)}
        </div>
        
        <div className="flex flex-row items-center mt-20">
          {this.renderLogos(width).slice(4, 6)}
        </div>

      </div>
    );
  }

  renderBlogSection() {

    return (

      <section className="bg-brandcream pt-20">

        <div className="w-full container flex flex-col ml-2 md:mx-auto lg:px-16">

          <div className={this.sectionHeaderStyle_BaseNew()}>
            Writing
          </div>

          <h1 className="w-11/12 md:w-6/12 text-xl text-red-100 md:text-xl text-left text-branddarkgrey font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug mt-6 lg:mt-6 mb-6 sm:mb-2">
            I write on about various tech startup topics and my work has been featured on “The Startup” and "Entrepreneur's Handbook". Full Blog is&nbsp;
            <a href={Constants.BLOG_URL} target="_blank" rel="noopener noreferrer" className="text-brandskyblue hover:text-brand-dark-blue-20 underline">
              here
            </a>.
          </h1>


          <div className="flex flex-wrap items-center my-6">

            <BlogItem
              imageUrl={"blog/blog_cofounder.jpeg"}
              title={"How to find a Technical Co-Founder for your Startup"}
              date={"24 April 2021"}
              descText={"Finding a co-founder is hard work. Finding a technical co-founder is even harder. Yet, the benefits of having a technical co-founder make it all worthwhile. A good co-founder will serve as a powerful..."}
              link={"https://entrepreneurshandbook.co/how-to-find-a-technical-co-founder-for-your-startup-4bb74524bad2"}
            />

            <BlogItem
              imageUrl={"blog/blog_startup_eng.jpeg"}
              title={"The Do’s and Don’ts for Early Stage Startup Engineering"}
              date={"11 November 2020"}
              descText={"Startup engineering in the early stages of a new tech venture is tricky to say the least. There is no escaping the fact that product engineering is one of the most critical components of a tech startup..."}
              link={"https://medium.com/swlh/the-dos-and-don-ts-for-early-stage-startup-engineering-e9b7de388d80"}
            />

            <BlogItem
              imageUrl={"blog/blog_sound_class.jpg"}
              title={"Sound Classification using Deep Learning"}
              date={"27 February 2019"}
              descText={"I recently completed Udacity’s Machine Learning Engineer Nanodegree Capstone Project, titled “Classifying Urban Sounds using Deep learning”, where I demonstrate how to classify different sounds using AI..."}
              link={"https://medium.com/@mikesmales/sound-classification-using-deep-learning-8bc2aa1990b7"}
            />

            {/* <BlogItem
              imageUrl={"blog/blog_sdk.png"}
              title={"Best Practices for building a world class Mobile SDK"}
              date={"4 September 2018"}
              descText={"Good Mobile SDK craftsmanship is a topic that often comes up with teams aiming to perfect their SDK strategy. The benefits of a well built SDK are clear. Improved developer experience, faster integration and rollout of new features, all which..."}
              link={"https://medium.com/@mikesmales/best-practices-for-building-a-world-class-mobile-sdk-b090d9e4b774"}
            /> */}

            {/* <BlogItem
              imageUrl={"blog/blog_always_on.png"}
              title={"Always On: Best practices for Audio UX on Microphone Enabled Devices"}
              date={"13 April 2018"}
              descText={"With the growing popularity of voice and audio-enabled products, such as the Amazon Echo and Google Home, it seems reasonable for consumers to be concerned about exactly what audio data is being collected, stored and shared..."}
              link={"https://medium.com/chirp-io/even-the-speakers-have-ears-c32df8b795b4"}
            /> */}

          </div>
        </div>

      </section>
    );
  }

  renderContact() {

    return (
      <section className="w-full h-auto container bg-brandwhite ml-2 md:mx-auto lg:px-16 mt-16 md:mt-32 mb-16" >

        <h1 className={this.sectionHeaderStyle_BaseNew()}>
          Contact
        </h1>

        <div className="h-auto container flex flex-wrap md:flex-nowrap mt-16" >

          <div className="w-full md:w-1/2 md:mr-4 ">

            <h1 className={this.mainContentBodyStyle_BaseNew() + " mb-6 sm:mb-2"}>
              The fastest way to contact me is to send an email or use the contact form. I can also be found on LinkedIn and Twitter.
              </h1>

          </div>


          <div className="w-full md:w-1/2 md:ml-4 ">

            <h1>
              <a href={"mailto:" + Constants.EMAIL} target="_blank" rel="noopener noreferrer" className="text-brandskyblue text-lg font-brandbody font-medium tracking-wide subpixel-antialiased hover:text-brand-dark-blue-20 no-underline">
                {Constants.EMAIL}
              </a>
            </h1>

            <h1>
              <a href={"/contact"} target="_blank" rel="noopener noreferrer" className="text-brandskyblue text-lg font-brandbody font-medium tracking-wide subpixel-antialiased hover:text-brand-dark-blue-20 no-underline">
                Contact form <strong>&gt;</strong>
              </a>
            </h1>

            <div className="flex flex-row mt-2">

              <button onClick={this.onClickLinkedIn} className="bg-transparent hover:border-transparent outline-none mr-2">
                <img src="icons/linkedin.png" alt="LinkedIn Profile" className="w-6" />
              </button>

              <button onClick={this.onClickTwitter} className="bg-transparent hover:border-transparent outline-none">
                <img src="icons/twitter.png" alt="Twitter Profile" className="w-6" />
              </button>

            </div>

          </div>

        </div>
      </section>
    );
  }

  render() {
    return (
      <div className="h-screen">

        <Header />

        <main className="">
          {this.renderTopSection()}
          {this.renderWhatIDo()}
          {this.renderAboutMe()}
          {this.renderConsultantCTO()}
          {this.renderDivider()}
          {this.renderStartupAdvisor()}
          {this.renderBlogSection()}
          {this.renderContact()}
        </main>

        <Footer />

      </div>
    );
  }

};

export default MainPage;