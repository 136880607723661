import React from "react";
import ReactLoading from "react-loading";

class LoadingButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      className: "flex justify-center items-center w-full h-12 bg-brand-primary hover:bg-brand-primary-light rounded outline-none focus:border-0 border-0 text-white text-sm font-sans font-normal tracking-wide subpixel-antialiased uppercase",
      height: 32,
      width: 32,
    }
  }

  componentDidMount() {
    if(this.props.className && this.props.height && this.props.width){
      this.setState({ 
        className: this.props.className,
        height: this.props.height,
        width: this.props.width,
      });
    }
  }

  render() {
    if (this.props.loading === true) {
      return (
        <div className={this.state.className}>
          <ReactLoading type={"spin"} color={"#ffffff"} height={this.state.height} width={this.state.width} />
        </div>
      )
    }

    return (
      <button className={this.state.className} onClick={() => this.props.onClick()}>{this.props.title}</button>
    )
  }
}

export default LoadingButton;
