import React from "react";
import * as Constants from '../other/Constants.js'

class Header extends React.Component {

  onClickHome(event) {
    window.open("/", '_self');
    return;
  }

  onClickLinkedIn(event) {
    window.open(Constants.LINKEDIN_URL, '_blank');
    return;
  }

  onClickTwitter(event) {
    window.open(Constants.TWITTER_URL, '_blank');
    return;
  }

  render() {
    return (

      <header className="w-full bg-auto" >

        <div className="w-full container flex flex-wrap mx-auto lg:px-16 items-center justify-between">

          <div className="md:w-2/12 w-6/12 font-brandbody text-brandnavyblue font-semibold antialiased text-3xl pt-8 pb-8">
            <button onClick={this.onClickHome} className="bg-transparent hover:border-transparent outline-none">
              <img src="logo.png" alt="Mike Smales Logo" className="" />
            </button>
          </div>

          <div className="flex flex-row ">

            <a href="/contact" rel="noopener noreferrer" className="text-xl text-brandgrey hover:text-brand-white font-semibold font-brandbody leading-normal tracking-wide antialiased no-underline mr-4 pt-1">Contact</a>

            <button onClick={this.onClickLinkedIn} className="bg-transparent hover:border-transparent outline-none mr-2">
              <img src="icons/linkedin.png" alt="LinkedIn Profile" className="w-8" />
            </button>

            <button onClick={this.onClickTwitter} className="bg-transparent hover:border-transparent outline-none mr:2 md:mr-0">
              <img src="icons/twitter.png" alt="Twitter Profile" className="w-8" />
            </button>


          </div>
        </div>
      </header>
    );
  }
}

export default Header;
