import emailjs from 'emailjs-com';

const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;

export const sendEmail = function (parameters, successCallback, errorCallback) {
  
  emailjs.init(EMAILJS_USER_ID);

  emailjs.send(EMAILJS_SERVICE_ID,'template_z38a1br', parameters)
	.then(function(response) {
     //console.log('SUCCESS!', response.status, response.text);
     successCallback();
	}, function(err) {
     //console.log('FAILED...', err);
     errorCallback();
	});
};