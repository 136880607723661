import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoadingButton from "../components/LoadingButton";
import * as Constants from '../other/Constants.js'
import * as EmailSender from '../other/EmailSender.js'
import validator from 'validator';

import '../css/tailwind.css';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btntitle: "Send message",
      formData: {
        name: "",
        email: "",
        subject: "",
        jobtitle: "",
        company: "",
        message: ""
      },
      formErrors: {
        name: "",
        email: "",
        subject: "",
        jobtitle: "",
        company: "",
        message: ""
      }
    }
    this.onClickScheduleMeeting = this.onClickScheduleMeeting.bind(this);
    this.onClickLinkedIn = this.onClickLinkedIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.inputFieldStyle = this.inputFieldStyle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /* Event handlers */
  onClickScheduleMeeting(event) {
    //console.log('The calendly button was clicked.');
    window.open(Constants.BOOKING_URL, '_blank');
    return;
  }

  onClickLinkedIn(event) {
    window.open(Constants.LINKEDIN_URL, '_blank');
    return;
  }

  handleInputChange(event) {

    let target = event.target;
    let name = target.name;
    let value = target.value;

    let formData = this.state.formData;
    formData[name] = value;

    this.setState({
      formData
    });
  }

  handleSubmit(event) {
    let formIsValid = this.validateForm();

    if (formIsValid) {
      this.setState({ loading: true });
      this.callAPI();
    }
  }

  validateForm() {

    let formData = this.state.formData;
    let errors = {};
    let formIsValid = true;

    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = "* Please enter your name.";
    }

    if (!formData["email"]) {
      formIsValid = false;
      errors["email"] = "* Please enter a valid email address.";
    }

    if (typeof formData["email"] !== "undefined") {

      formData["email"] = formData["email"].replace(/\s/g, "");

      if (!validator.isEmail(formData["email"])) {
        formIsValid = false;
        errors["email"] = "* Please enter a valid email address.";
      }
    }

    if (!formData["subject"]) {
      formIsValid = false;
      errors["subject"] = "* Please enter a subject.";
    }

    if (!formData["message"]) {
      formIsValid = false;
      errors["message"] = "* Please choose your message.";
    }

    this.setState({
      formErrors: errors
    });
    return formIsValid;
  }

  callAPI() {
    let requestPayload = this.state.formData;

    EmailSender.sendEmail(requestPayload, (responsePayload) => {
      this.setState({
        loading: false,
        btntitle: "Sent!"
      });
    },
      (response) => {
        this.setState({
          loading: false,
          btntitle: "Error sending message"
        });
      });
  }



  /* Style */
  sectionHeaderStyle_BaseNew() {
    return "text-4xl text-left font-brandheader font-bold text-brandnavyblue"
  }

  mainContentBodyStyle_BaseNew() {
    return "text-lg md:text-lg text-left text-branddarkgrey font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug"
  }

  mainContentBodyStyle_BaseNewEmphasis() {
    return "text-lg md:text-lg text-left text-brandskyblue font-brandbody font-light tracking-wide subpixel-antialiased sm:leading-tight lg:leading-snug"
  }

  labelFieldStyle_Base() {
    return "block text-branddarkgrey text-sm font-semibold font-brandbody tracking-normal subpixel-antialiased uppercase mb-2";
  }

  inputFieldStyle_Base() {
    return "w-full shadow appearance-none rounded border outline-none focus:border-brand-primary text-branddarkgrey text-base font-brandbody font-sans tracking-normal subpixel-antialiased py-2 px-3  mb-3";
  }

  errorFieldStyle_Base() {
    return "text-red-500 text-xs font-normal font-brandbody tracking-normal subpixel-antialiased italic";
  }

  mandatoryFieldStyle_Base() {
    return "text-red-500 text-md tracking-normal font-semibold subpixel-antialiased";
  }

  inputFieldStyle(errorParam, dataParam) {
    let style = this.inputFieldStyle_Base();

    if (errorParam) {
      style += " border-red-500";
    }
    else if (dataParam) {
      style += " border-green-300";
    }
    return style;
  }

  renderContact() {

    return (

      <section className="bg-brandcream pt-10 md:pt-20 pb-12">

        <div className="w-full container flex flex-col ml-2 md:mx-auto lg:px-16">

          <h1 className={this.sectionHeaderStyle_BaseNew()}>
            Contact me
          </h1>

          <h1 className={this.mainContentBodyStyle_BaseNew() + " w-11/12 md:w-5/12 mt-6 lg:mt-6 mb-6 sm:mb-2"}>
            The fastest way to contact me is to send an email or use the contact form below. I can also be found on LinkedIn and Twitter.
          </h1>

          <h1>
            <a href={"mailto:" + Constants.EMAIL} target="_blank" rel="noopener noreferrer" className="text-brandskyblue text-lg font-brandbody font-medium tracking-wide subpixel-antialiased hover:text-brand-dark-blue-20 no-underline">
              {Constants.EMAIL}
            </a>
          </h1>

        </div>

      </section>
    );
  }

  renderContactForm() {

    return (
      <section className="w-full flex flex-col h-auto container items-start justify-center ml-2 md:mx-auto lg:px-16 pt-6 md:pt-4 pb-16" >

        <div className="w-2/3 md:w-1/3 mb-4 ">
          <div className="flex">
            <label className={this.labelFieldStyle_Base()} htmlFor="name">
              Your name
          </label>
            <label className={this.mandatoryFieldStyle_Base()}>
              &nbsp;*
          </label>
          </div>
          <input className={this.inputFieldStyle(this.state.formErrors.name, this.state.formData.name)} name="name" type="text" placeholder="Your Name" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.name}</p>
        </div>

        <div className="w-2/3 md:w-1/3  mb-4 ">
          <div className="flex">
            <label className={this.labelFieldStyle_Base()} htmlFor="email">
              Your email
          </label>
            <label className={this.mandatoryFieldStyle_Base()}>
              &nbsp;*
          </label>
          </div>
          <input className={this.inputFieldStyle(this.state.formErrors.email, this.state.formData.email)} name="email" type="text" placeholder="Your Email" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.email}</p>
        </div>

        <div className="w-2/3 md:w-1/3  mb-4 ">
          <div className="flex">
            <label className={this.labelFieldStyle_Base()} htmlFor="subject">
              Subject
          </label>
            <label className={this.mandatoryFieldStyle_Base()}>
              &nbsp;*
          </label>
          </div>
          <input className={this.inputFieldStyle(this.state.formErrors.subject, this.state.formData.subject)} name="subject" type="text" placeholder="Subject" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.subject}</p>
        </div>

        <div className="w-2/3 md:w-1/3  mb-4 ">
          <label className={this.labelFieldStyle_Base()} htmlFor="jobtitle">
            Your Jobtitle
          </label>
          <input className={this.inputFieldStyle(this.state.formErrors.jobtitle, this.state.formData.jobtitle)} name="jobtitle" type="text" placeholder="Your jobtitle" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.jobtitle}</p>
        </div>

        <div className="w-2/3 md:w-1/3  mb-4 ">
          <label className={this.labelFieldStyle_Base()} htmlFor="company">
            Company
          </label>
          <input className={this.inputFieldStyle(this.state.formErrors.company, this.state.formData.company)} name="company" type="text" placeholder="Your Company" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.company}</p>
        </div>

        <div className="w-2/3 md:w-1/3  mb-4 ">
          <div className="flex">
            <label className={this.labelFieldStyle_Base()} htmlFor="message">
              Your message
          </label>
            <label className={this.mandatoryFieldStyle_Base()}>
              &nbsp;*
          </label>
          </div>
          <textarea rows="14"  className={this.inputFieldStyle(this.state.formErrors.message, this.state.formData.message)} name="message" type="text" placeholder="Your Message" onChange={this.handleInputChange} />
          <p className={this.errorFieldStyle_Base()}>{this.state.formErrors.message}</p>
        </div>

        <div className="flex items-start">
          <LoadingButton
            title={this.state.btntitle}
            loading={this.state.loading}
            onClick={this.handleSubmit}
            className="flex justify-center items-center w-full h-12 px-2 bg-brandnavyblue hover:bg-brandskyblue-dark rounded outline-none focus:border-0 border-0 text-brandwhite text-sm font-brandbody font-normal tracking-wide subpixel-antialiased uppercase"
            height={32}
            width={32}
          />
        </div>

      </section>
    );
  }

  render() {
    return (
      <div className="h-screen">

        <Header />

        <main className="bg-brandcream">
          {this.renderContact()}
          {this.renderContactForm()}
        </main>

        <Footer />

      </div>
    );
  }

};

export default ContactPage;