import React from "react";

class BlogItem extends React.Component {
  
  render() {
    return (
      <div className="w-full md:w-1/3 lg:w-1/3 p-4">

        <div className="mx-auto max-w-sm overflow-hidden h-full mb-4">

          <img className="mb-4" src={this.props.imageUrl} alt={"Blog header for " + this.props.title} />

          <div className="text-xl text-left text-brandnavyblue font-body font-medium tracking-wide leading-normal antialiased mt-4">
            {this.props.title}
          </div>

          <div className="text-sm text-left text-brandgrey font-body font-normal tracking-normal leading-normal antialiased mb-0">
            {this.props.date}
          </div>

          <div className="border-t-2 border-brandskyblue mt-4"></div>

          <p className="text-sm text-left text-branddarkgrey font-body font-normal tracking-normal leading-normal antialiased mt-4">
            {this.props.descText}
            <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="text-brandskyblue hover:text-brand-dark-blue-20 no-underline text-sm font-semibold font-body leading-normal tracking-wide antialiased p-3">
              Read more >
            </a>
          </p>


        </div>

      </div>

    );
  }
}

export default BlogItem;
