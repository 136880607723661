import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import HttpsRedirect from 'react-https-redirect';

import MainPage from "./pages/Index";
import ContactPage from "./pages/Contact";
import NotFoundPage from "./pages/404";

import './css/output.css'

const GATrackingId = process.env.REACT_APP_GA_TRACKING_ID;
const HotjarId = process.env.REACT_APP_HOTJAR_ID;
const HotjarSnippetId = process.env.REACT_APP_HOTJAR_SNIPPET_ID;
ReactGA.initialize(GATrackingId);
hotjar.initialize(HotjarId, HotjarSnippetId);

const httpsRedirectDisabled = false;

class App extends Component {

  trackPageView() {
      var pageName = window.location.pathname;
      //console.log("Router onUpdate " + pageName);
      ReactGA.pageview(pageName);
  }

  render() {
      return (
          <HttpsRedirect disabled={httpsRedirectDisabled}>
              <Router onUpdate={this.trackPageView()}>
                  <Switch>
                      <Route exact path="/" component={MainPage} />
                      <Route exact path="/contact" component={ContactPage} />
                      <Route exact path="/404" component={NotFoundPage} />
                      <Redirect to="/404"></Redirect>
                  </Switch>
              </Router>
          </HttpsRedirect>
      );
  }
}

export default App;
